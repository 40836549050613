<template>
    <Head>
        <meta charset="utf-8" />
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
        />

        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
        />
        <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
        />
        <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
    </Head>
    <main
        :data-app-swingu="true"
        :data-browser="true"
        data-theme="default"
        class="antialiased min-h-screen flex flex-col items-center bg-[var(--background-color)] text-[var(--grey-800)]"
    >
        <header class="w-full h-[86px] bg-[var(--grey-900)]">
            <Nav :player-name="auth.user.username" class="[@media(min-width:992px)]:flex hidden" />
            <MobileNav class="[@media(min-width:992px)]:hidden flex" />
        </header>
        <article class="w-full">
            <slot />
        </article>
        <SwingUFooter class="w-full mt-xl" />
    </main>
</template>

<script setup>
import Nav from "@components/app-swingu/header-nav.vue";
import MobileNav from "@components/app-swingu/mobile-header-nav.vue";
import SwingUFooter from "@global/layout/swingu-com/layout-footer.vue";
</script>

<script>
export default {
    props: {
        auth: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
        };
    },
    methods: {
    },
};
</script>

 <style lang="scss">
 /** 
    There is an overflow-y-scroll in resources/views/swingu/app.blade.php that overrides any scoll-locked style applied via vue final modal.
    Using logic from app.swingu, toggle a modal-active class when modal is opened/closed in order to lock the screen behind it.

    Style needs to be applied here in order to reach the scope of the first html parent element.
 */
 .modal-active {
    height: 100%;
    overflow: hidden;
}
 </style>